.ant-btn {

  &:focus,
  &:hover,
  &:active {
    color: var(--text-color) !important;
    background: #ffffff !important;
    border-color: var(--border-color) !important;
  }

  &:not(.ant-btn-circle) {
    border-radius: 4px !important;
  }
}

.ant-btn-primary {
  color: #ffffff !important;
  background: var(--bg-color) !important;
  border: var(--border-style) !important;
  &:focus,
  &:hover,
  &:active {
    color: #ffffff !important;
    background: var(--bg-light-color) !important;
    border: var(--border-style) !important;
  }
}

.ant-btn-background-ghost,
.ant-btn-background-ghost.ant-btn-primary {
  color: var(--text-color) !important;
  border: var(--border-style) !important;
}

.ant-switch {
  &:focus {
    box-shadow: var(--box-shadow-color) !important;

    &:hover {
      box-shadow: var(--box-shadow-color) !important;
    }
  }
}

.ant-switch-checked {
  background-color: var(--bg-color) !important;

  &:focus,
  &:active {
    box-shadow: var(--box-shadow-color) !important;
  }
}

.ant-input-focused {
  border-color: var(--border-color) !important;
}

.ant-input {

  &:focus,
  &:hover {
    border-color: var(--border-color) !important;
    box-shadow: var(--box-shadow-color) !important;
  }
}

.ant-input-number-focused,
.ant-input-number:hover {
  border-color: var(--border-color) !important;
  box-shadow: var(--box-shadow-color) !important;
}

.ant-input-number {

  &:focus,
  &:hover,
  &:active {
    box-shadow: var(--box-shadow-color) !important;
  }
}

.ant-pagination-options-quick-jumper input-focused,
.ant-pagination-options-quick-jumper input {

  &:focus,
  &:hover {
    border-color: var(--border-color) !important;
    box-shadow: var(--box-shadow-color) !important;
  }
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled) {

  &:focus,
  &:hover,
  &:active {
    border-color: var(--border-color) !important;
    box-shadow: var(--box-shadow-color) !important;
  }
}

.ant-input-affix-wrapper-focused {
  border-color: var(--border-color) !important;
  box-shadow: var(--box-shadow-color) !important;
}

.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper {
  .ant-input {

    &:focus,
    &:hover {
      border-color: var(--border-color) !important;
      box-shadow: unset !important;
    }
  }

  &:focus,
  &:hover,
  &:active {
    border-color: var(--border-color) !important;
    box-shadow: var(--box-shadow-color) !important;
  }
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: var(--border-color) !important;
  border-right-width: 1px !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: var(--border-color) !important;
  box-shadow: var(--box-shadow-color) !important;
}

.ant-select-item-option,
.ant-select-selection-item {
  color: var(--grayblue-color) !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: var(--bg-alpha-color) !important;
}

.ant-layout {
  background: #f6f8f9 !important;
}

.ant-layout-sider {
  background: #ffffff !important;

  .ant-menu-inline {
    border: unset !important;
  }
}

.ant-layout-content {
  overflow-y: auto !important;
}

.ant-menu {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;

  .ant-menu-item,
  .ant-menu-item-selected {
    width: 83.333333% !important;
    border-radius: 0.5rem !important;

    a {
      display: flex !important;
      align-items: center !important;
      position: relative !important;
    }

    &::after {
      border: unset !important;
    }
  }
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: var(--bg-alpha-color) !important;

  &:active,
  &:focus {
    background-color: var(--bg-alpha-color) !important;
  }
}

.ant-menu-item:active {
  background-color: var(--bg-alpha-color) !important;
}

.ant-picker-focused,
.ant-picker:hover {
  border-color: var(--border-color) !important;
  box-shadow: var(--box-shadow-color) !important;
}

.ant-picker-range .ant-picker-active-bar {
  background: var(--bg-color) !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  background: var(--bg-color) !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: var(--border-color) !important;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background-color: var(--bg-alpha-color) !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before {
  background-color: var(--bg-alpha-color) !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  background-color: var(--bg-alpha-color) !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
  border-color: var(--border-color) !important;
}

.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after,
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after {
  background-color: rgba(173, 122, 212, .2) !important;
}

.ant-slider-track {
  background-color: var(--bg-color) !important;
}

.ant-slider-handle {
  border-color: var(--border-color) !important;
}

th.ant-table-cell {
  padding: 8px !important;
  color: var(--grayblue-color) !important;
}

tr.ant-table-row {
  td.ant-table-cell {
    padding: 13px !important;
    font-size: 12px;
  }
}

.ant-picker-input input {
  color: var(--grayblue-color) !important;
}

.ant-modal-body {
  max-height: 80vh;
  overflow-y: auto;
}

.ant-tabs-tab {
  padding: 12px 0.5rem !important;

  &:hover,
  &:focus,
  &:active {
    color: var(--text-color) !important;
  }
}

.ant-tabs-tab-btn {

  &:hover,
  &:focus,
  &:active {
    color: var(--text-color) !important;
  }
}

.ant-tabs-tab.ant-tabs-tab-active {
  .ant-tabs-tab-btn {
    color: var(--text-color) !important;

    &:hover,
    &:focus,
    &:active {
      color: var(--text-color) !important;
    }
  }
}

.ant-tabs-ink-bar {
  background-color: var(--bg-color) !important;
}

.paginatorInfo {
  .ant-pagination-item {
    a {
      color: black !important;
    }
    &:hover,
    &:focus,
    &:active {
      a {
        color: var(--text-color) !important;
      }
    }
  }
  .ant-pagination-item-active {
    border: unset;
    a {
      color: var(--text-color) !important;
    }
  }
}

.ant-drawer-header {
  padding: 0.25rem 1.5rem !important;
}

.ant-drawer-body {
  padding: 0 !important;
}

.ant-popover {
  border-radius: 0.25rem !important;

  .ant-popover-content {
    border-radius: 0.25rem !important;

    .ant-popover-inner {
      border-radius: 0.25rem !important;

      .ant-popover-inner-content {
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: start !important;
        gap: 0.25rem !important;
        border-radius: 0.25rem !important;

        button {
          display: flex !important;
          justify-content: start !important;
          align-items: center !important;
        }
      }
    }
  }
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--bg-color) !important;
  border-color: var(--border-color) !important;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: var(--bg-color) !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner {
  border-color: var(--border-color) !important;
}

.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
}