@tailwind base;
@tailwind components;
@tailwind utilities;

// @import "~@angular/cdk/overlay-prebuilt.css";/* You can add global styles to this file, and also import other style files */
@import '~@angular/cdk/text-field/text-field';
@include cdk-text-field();

@import './scss/material';
@import './scss/ant-modal';

:root {
  .pca4 {
    --color: #1f93ff !important;
    --light-color: #40a9ff !important;
    --grayblue-color: #606266 !important;
    --alpha-color: rgba(24, 144, 255, .15) !important;
    --text-color: var(--color) !important;
    --bg-color: var(--color) !important;
    --bg-light-color: var(--light-color) !important;
    --bg-alpha-color: var(--alpha-color) !important;
    --border-color: var(--color) !important;
    --border-style: 1px solid var(--border-color) !important;
    --box-shadow-color: 0 0 0 2px var(--alpha-color) !important;
    --outline-color: var(--color) !important;
    --outline-style: 2px solid var(--outline-color) !important;
    --bank-active-card: url(./assets/images/pca4_credit_card_light.png) 50% 50% no-repeat;
  }

  .pca5 {
    --color: #ad7ad4 !important;
    --light-color: #b98add !important;
    --grayblue-color: #606266 !important;
    --alpha-color: rgba(173, 122, 212, .15) !important;
    --text-color: var(--color) !important;
    --bg-color: var(--color) !important;
    --bg-light-color: var(--light-color) !important;
    --bg-alpha-color: var(--alpha-color) !important;
    --border-color: var(--color) !important;
    --border-style: 1px solid var(--border-color) !important;
    --box-shadow-color: 0 0 0 2px var(--alpha-color) !important;
    --outline-color: var(--color) !important;
    --outline-style: 2px solid var(--outline-color) !important;
    --bank-active-card: url(./assets/images/pca5_credit_card_light.png) 50% 50% no-repeat;
  }
}

a {
  color: var(--text-color) !important;
}

th,
td {
  color: #606266;
}

.color-primary {
  color: mat-color($app-primary) !important;
}

.color-accent {
  color: mat-color($app-accent, A700) !important;
}

// icon
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(/assets/font/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

@font-face {
  // 先註解掉字型，因檔案太大，等找到小檔案字型再換上
  // font-family: "Hiragino Sans GB";
  // src: url("../src/assets/font/HiraginoSansGB.otf") format("opentype");
}

html,
body {
  height: 100%;
  width: 100%;
  font-family: "Hiragino Sans GB" !important;
}

.dark-backdrop {
  background: #0000004d;
}